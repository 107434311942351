<template>
<div id="main-wrapper">
  <b-overlay
    :show="loadingOverlay"
    rounded="sm"
    z-index="50"
  >
    <slot name="header">
      <Header></Header>
    </slot>
    <slot name="sidebar">
      <Sidebar></Sidebar>
    </slot>
    <div class="page-wrapper">
      <router-view/>
      <slot name="loader"></slot>
    </div>
    <Footer></Footer>
  </b-overlay>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import Header from '@view/include/Header'
import Sidebar from '@view/include/Sidebar'
import Footer from '@view/include/Footer'

export default {
  extends:GlobalVue,
  components:{Header, Sidebar, Footer},
}
</script>
<style>
.page-wrapper{min-height:660px !important}
</style>