<template>
<aside class="left-sidebar">
  <VuePerfectScrollbar class="scroll-sidebar">
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <template v-for="(val, key) in menuData">
            <li 
              v-if="val.isPermit && val.head" 
              :key="key" 
              class="nav-small-cap text-uppercase font-size-xs line-height-xs"
            >
              --- {{ val.head }}
            </li>
            <template v-else-if="(val.child||[]).length">
              <li 
                v-if="childIsPermit(val.child||[]).length" 
                :key="key"
                @click="clickMenu($event)"
              >
                <a 
                  href="javascript:void(0)" 
                  class="has-arrow waves-effect waves-dark"
                >
                  <i :class="val.icon"></i>
                  <span class="hide-menu">{{ val.name }}</span>
                </a>
                <ul 
                  class="collapse" 
                  :data-submenu-title="val.name">
                    <template v-for="(val2, key2) in val.child">
                      <li 
                        v-if="val2.isPermit" 
                        :key="key2" 
                        class="nav-item" 
                      >
                        <router-link :to="{name:val2.link, query: val2.query||{}}" :data-link="val2.link" >
                          {{ val2.name }}
                        </router-link>
                      </li>
                    </template> 
                </ul>
              </li>
            </template>
            <li 
              v-else-if="val.isPermit" 
              :key="key" 
              :class="{'active_':val.link==$route.name}"
            >
              <router-link 
                :to="{name:val.link, query:val.query||{}}" 
                :title="val.name"
                class="waves-effect waves-dark"  aria-expanded="false"
                :data-link="val.link"
              >
                <i :class="val.icon"></i>
                <span class="hide-menu"> {{ val.name }} </span>
              </router-link>
            </li>
          </template>

          <!-- <li @click="doLogout(true)"> 
            <a 
              href="javacript:;"
              class="waves-effect waves-dark" 
              aria-expanded="false"
            >
              <i class="far fa-circle text-danger"></i>
              <span class="hide-menu">Log Out</span>
            </a>
          </li> -->
        </ul>
      </nav> 
    </VuePerfectScrollbar>   
</aside>
</template>

<script>
import GlobalVue from '@libs/Global'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
let $ = global.jQuery
let _ = global._
export default{
  extends:GlobalVue,
  components: {
    VuePerfectScrollbar
  },
  data(){
    return {
      loaded:false,
      menu:[
				{ 
          head:"Main",
          related: [
            'Dashboard', 'RegisterResiko', 'Penaksiran',
            'ProfileResiko', 'LostEventDatabase', 'CariDokumenBulan',
            'CariDokumenTriwulan', 'RisikoTerbaru', 'RisikoKorporat', 'Delegasi'
          ]
        },
				{ 
          name:"Dashboard", 
          icon:"ti-home",
          link:"Dashboard"
        },
        { 
          name:"Register Risiko", 
          icon:"ti-pencil-alt",
          link:"RegisterResiko",
          is_employeed:true
        },
        { 
          name:"Penaksiran", 
          icon:"ti-marker-alt",
          link:"Penaksiran",
          query:{tab:'bulan'},
          is_employeed:true
        },
        { 
          name:"Mitigasi", 
          icon:"ti-server",
          link:"PenaksiranMitigasi",
          is_employeed:true
        },
        { 
          name:"Profile Risiko", 
          icon:"ti-map-alt",
          link:"ProfileRisiko"
        },
        { 
          name:"Cari Dokumen", icon:"ti-file", 
          child:[
            {
              name:"Bulan", 
              link:"CariDokumenBulan"
            },
            {
              name:"Triwulan", 
              link:"CariDokumenTriwulan"
            },
          ] 
        },
        { 
          name:"Loss Event Database", 
          icon:"ti-server",
          link:"LostEventDatabase"
        },
        { 
          name:"Risiko Terbaru", 
          icon:"icon-book-open",
          link:"RisikoTerbaru"
        },
        { 
          name:"Risiko Korporat", 
          icon:"far fa-building",
          child: [
            {
              name: 'Daftar Risiko',
              link: 'RisikoKorporat'
            },
            {
              name: 'Detail Risiko',
              link: 'DetailRisikoKorporat',
              query:{tab:'bulan'}
            },
          ]
        },
        { 
          name:"Delegasi", 
          icon:"far fa-handshake",
          link:"Delegasi"
        },

        { 
          head:'Laporan',
          related:[
            'Laporan'
          ]
        },
        { 
          name:"Laporan", 
          icon:"ti-file",
          link:"Laporan"
        },

        { 
          head:"Setting",
          related:[
            'MrPustakaRisiko', 'MrPerspektif', 'SasaranRtu',
            'MrUnitKerja', 'MrKantor', 'MrCatResiko', 
            'MrJabatan', 'TandaTangan', 'Content', 'Users', 'UserLevel', 'ManagePeriode', 'MrBidang'
          ]
        },
        {
          name: 'Atur Periode',
          icon: 'ti-settings',
          link: 'ManagePeriode'
        },
        {
          name: 'Ganti Kantor',
          icon: 'ti-settings',
          link: 'GantiKantor'
        },
        { name:"Master Data", icon:"icon-folder-alt", 
          child:[
            {
              name:"Bidang", 
              link:"MrBidang"
            },
            {
              name:"Pustaka Risiko", 
              link:"MrPustakaRisiko"
            },
            {
              name:"Sasaran RTU", 
              link:"SasaranRtu"
            },
            {
              name:"Perspektif", 
              link:"MrPerspektif"
            },
            {
              name:"Unit Kerja", 
              link:"MrUnitKerja"
            },
            {
              name:"Kantor", 
              link:"MrKantor"
            },
            {
              name:"Kategori", 
              link:"MrCatResiko"
            },
            {
              name:"PIC", 
              link:"MrJabatan"
            },
            {
              name:"Tandatangan", 
              link:"Tandatangan"
            },
            {
              name: "Template Konten",
              link: "Content"
            }
          ] 
        },
        { name:"Users Management", icon:"icon-people", 
					child:[
						{
              name:"Daftar User", 
              link:"Users"
            },
						{
              name:"User Akses", 
              link:"UserLevel"
            },
					] 
				},

        { 
          head:"Support",
          related: [
            'AuditTrail'//, 'ErrorLog'
          ]
        },
        { 
          name:"Audit Trail", 
          icon:"ti-menu-alt",
          link:"AuditTrail"
        },
        {
          name: 'Konfigurasi',
          icon: 'ti-settings',
          link: 'Konfigurasi'
        }
        /* { 
          name: "Error Log", 
          icon: "ti-menu-alt",
          link: "ErrorLog"
        } */
      ]
    }
  },
  mounted(){
    this.initSideBar()
  },
  computed:{
		menuData(){
			if(!this.user.id) return false
      let menu = this.menu
      menu.map((v)=>{
        if(v.head){
          v.isPermit = _.intersection(v.related, this.user.menuRole).length
          if(['SADMIN','SUPAD'].indexOf(this.user.levelCode)>-1 || this.moduleRole('isAdministratorSystem')) v.isPermit = true

        }else{
          v.isPermit = v.link ? this.user.menuRole.indexOf(v.link)>-1 : true
          if(['SADMIN','SUPAD'].indexOf(this.user.levelCode)>-1 || this.moduleRole('isAdministratorSystem')) v.isPermit = true
          else if(v.is_employeed && v.isPermit) v.isPermit = this.user.officeId && this.user.unitKerjaId

          if(v.child){
            v.child.map((v2)=>{
              v2.isPermit = v2.link ? this.user.menuRole.indexOf(v2.link)>-1 : true
              if(['SADMIN','SUPAD'].indexOf(this.user.levelCode)>-1 || this.moduleRole('isAdministratorSystem')) v2.isPermit = true
              else if(v2.is_employeed && v2.isPermit) v2.isPermit = this.user.officeId && this.user.unitKerjaId
            })
          }
        }
      })
      return menu
		}
	},
  methods:{
    childIsPermit(child){
      return _.filter(child, (v)=>{
        return v.isPermit
      })
    },
    clickMenu(e){
      let target = $(e.target.closest("a"))
      if (!target.hasClass("active")) { 
        // hide any open menus and remove all other classes
        $("ul", target.parents("ul:first")).removeClass("in");
        $("a", target.parents("ul:first")).removeClass("active");
        // open our new menu and add the open class
        target.next("ul").addClass("in");
        target.addClass("active");
      }
      else if (target.hasClass("active")) {
        target.removeClass("active");
        target.parents("ul:first").removeClass("active");
        target.next("ul").removeClass("in");
      }
    },
    initSideBar(){
      setTimeout(() => {
        const navItem = $("a.router-link-active").parents("#sidebarnav li");
        $("#sidebarnav li").removeClass("active")
        
        navItem.addClass('active');
        $("#sidebarnav li:has(.active) ul.collapse").addClass("in")
        $("#sidebarnav li:not(.active) ul.collapse").removeClass("in") 
        $("#sidebarnav li:has(.active) a.has-arrow").addClass("active")
        $("#sidebarnav li:not(.active) a.has-arrow").removeClass("active")

        $("#sidebarnav a.router-link-active").addClass("fw-bold")
      }, 150)
      

    }
  },
  watch:{
    $route(){
      this.initSideBar()
    }
	}
}
</script>
<style>
.sidebar-nav>ul>li.active_>a {
    border-left: 3px solid #0361cf !important;
    background: #0097d8 !important;
}
</style>