<template>
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-dark">
    <div class="navbar-header">
      <a class="navbar-brand" :href="baseUrl">
				<b>
					<img 
						:src="baseUrl+'/images/logo-icon.png'" 
						alt="homepage" 
						class="dark-logo" 
					/>
					<img 
						:src="baseUrl+'/images/logo-light-icon.png'" 
						alt="homepage" 
						class="light-logo" 
					/>
				</b>
				<span class="ml-1">
					<img 
						:src="baseUrl+'/images/logo-text_white.png'" 
						alt="homepage" 
						class="dark-logo" 
					/>
					<img :src="baseUrl+'/images/logo-light-text.png'" 
						class="light-logo" 
						alt="homepage" 
					/>
				</span>
      </a>
    </div>
    <div class="navbar-collapse">
      <ul class="navbar-nav mr-auto">
          <li class="nav-item"> 
						<a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i>
						</a> 
					</li>
          <li class="nav-item"> 
						<a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i>
						</a> 
					</li>
          <!-- <li class="nav-item">
						<form class="app-search d-none d-md-block d-lg-block">
							<input type="text" class="form-control" placeholder="Search & enter">
						</form>
          </li> -->
      </ul>

			<b-nav 
				class="navbar-nav my-lg-0"
			>
				<b-nav-item
					v-if="!!user.id_ghost" 
					toggle-class="text-decoration-none" 
					no-caret
					right
				>	
					<span class="badge badge-success">Ganti Kantor</span>
				</b-nav-item>
				<b-nav-item
					v-if="!!user.id_ghost" 
					variant="link" 
					toggle-class="text-decoration-none" 
					no-caret
					right
					v-b-tooltip.hover="'Kembali Ke Root'"
					@click="doCancelGantiKantor"
				>
					<a href="javascript:;">
						<i class="icon-action-undo text-white"></i>
					</a>
				</b-nav-item>
				<b-nav-item-dropdown
					variant="link" 
					toggle-class="text-decoration-none" 
					no-caret
					right
					v-b-tooltip.hover="'Notifikasi'"
					ref="btnNotif"
					@show="toReadNotif()"
				>
					<template #button-content>
						<i class="ti-bell"></i>
						<div 
							v-if="user.is_notread_notif=='Y'"
							class="notify"
						> 
							<span class="heartbit"></span> 
							<span class="point"></span> 
						</div>
					</template>
					<div class="mailbox animated">
						<ul>
							<li>
								<div class="drop-title">Notifikasi</div>
							</li>
							<li>
								<div 
									:class="{
										'message-center':1, 
										'notification-scroll':user.notifications.length, 
										'notification-auto':!user.notifications.length
									}"
								>
									
									<span v-if="user.notifications.length">
										<a 
											v-for="(v, k) in user.notifications" 
											:key="k"
											style="color: #000;"
											:class="{'notread':v.is_read=='N'}"
											href="javascript:;"
											@click="doClickNotif(v,k)"
										>
											<div class="mail-content">
												<span class="mail-desc">
													{{v.activity.limitWord(12)}}
												</span> 
												<small class="time d-block">
													{{ v.created_date | moment("DD MMMM YYYY, HH:mm") }} WIB
												</small> 
											</div>
										</a>
									</span>
									<p v-else class="text-center mt-3">
										Belum ada Notifikasi
									</p>

								</div>
							</li>
							<li v-if="user.notifications.length > 0">
								<router-link style="color:#777 !important;" class="nav-link text-center link link_notify" :to="{name: 'Notification'}"> 
									<strong>Lihat Semua Notifikasi</strong> <i class="fa fa-angle-right"></i> 
								</router-link>
							</li>
						</ul>
					</div>
				</b-nav-item-dropdown>
				<router-link
					:to="{name: 'Mail', params	:{tab: 'pesan-masuk'}}"
					class="d-flex align-items-center position-relative mail mx-2"
					v-b-tooltip.hover="'Pesan'"
				>
					<b-nav-item-dropdown
						variant="link" 
						toggle-class="text-decoration-none" 
						no-caret
						right
					>
						<template #button-content>
							<router-link :to="{name: 'Mail', params:{tab: 'pesan-masuk'}}">
								<i class="icon-note text-white"></i>
								<div class="notify" style="top: -14px;" v-if="user.mail.length"> 
									<span class="heartbit"></span> 
									<span class="point"></span> 
								</div>
							</router-link>
						</template>
						<b-dropdown-item style="display: none">
							Test
						</b-dropdown-item>
					</b-nav-item-dropdown>
				</router-link>
				<!-- <b-nav-item
					data-toggle="modal" 
					data-target="#responsive-modal" 
					class="right-side-toggle"
					@click="openChangeOffice()"
				>
					<i class="far fa-building"></i>
					<span class="m-l-5 hidden-xs">Ganti Kantor</span>
				</b-nav-item> -->
				<b-nav-item-dropdown
					class="u-pro profile-pic"
					variant="link" 
					toggle-class="text-decoration-none" 
					no-caret
					right 
					style="margin-top:-5px"
				>
					<template #button-content>
						<img 
							width="30" 
							:src="picUser" 
							alt="user-img" class="img-circle mr-2"
						/> 
						<span class="hidden-md-down"> 
							<span class="m-r-5 hidden-xs" style="position:relative;">
								{{user.fullName||user.username}}
								<small style="position: absolute; left: 5px; bottom: -33px;">
									{{ user.user_ghost ? user.user_ghost.level : user.level }}
								</small>
							</span> 
							<i class="fa fa-angle-down"></i>
						</span> 
					</template>
					<b-dropdown-item :to="{name: 'AccountSetting'}">
						<router-link :to='{name: "AccountSetting"}'>
							<i class="far fa-building"></i> Account Setting
						</router-link>
					</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item 
						@click="doLogOutSession"
					>
						<i class="fa fa-power-off"></i> Akhiri Sesi
					</b-dropdown-item>
					<b-dropdown-item 
						@click="doLogout(true)"
					>
						<i class="fa fa-power-off"></i> Logout
					</b-dropdown-item>
				</b-nav-item-dropdown>
      </b-nav>

			<!-- <b-modal 
        title="Ganti Kantor"
				id="ModalKantor"
				size="md"
				hide-footer
      >

				<template>
					<ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
            <form @submit.prevent="handleSubmit(doSubmitCRUD)">
							<div class="form-group">
								<label class="control-label">
									User Level <span class="text-danger">*</span> 
								</label>
								<VSelect
									v-model="row.bu_level" 
									placeholder="-- Pilih Salah Satu --" 
									:options="mrLevel"
									:reduce="v=>v.value" 
								/>
							</div>
							<div class="form-group">
								<label class="control-label">
									Kantor <span class="text-danger">*</span> 
								</label>
								<VSelect
									v-model="row.bu_office_id" 
									placeholder="-- Pilih Salah Satu --" 
									:options="mrKantor"
									:reduce="v=>v.value"
								/>
							</div>
							<div class="form-group">
								<label class="control-label">
									Unit Kerja <span class="text-danger">*</span> 
								</label>
								<VSelect
									v-model="row.bu_unit_kerja_id" 
									placeholder="-- Pilih Salah Satu --" 
									:options="mrUnitKerja"
									:reduce="v=>v.value" 
								/>
							</div>
							<div class="row mt-3">
								<div class="col-md-6 text-left">
									<button type="button" class="btn btn-default btn-rounded waves-effect" data-dismiss="modal" @click="closeChangeOffice()">Tutup</button>
									<button type="submit" class="btn btn-info waves-effect btn-rounded waves-light">Ganti</button>
								</div>
								<div class="col-md-6 text-right">
									<button type="button" class="btn waves-effect btn-rounded waves-light btn-outline-dark">Kembali Sebagai Root</button>
								</div>
							</div>
						</form>
					</ValidationObserver>
				</template>
      </b-modal> -->
  </div>
  </nav>
</header>
</template>

<style lang="scss" scoped>
.notification-auto{
	height: auto !important;
}

.notification-scroll{
	min-height: 100px !important;
	max-height: 300px !important;
}

.mailbox{
	min-width: 350px !important;
}

.text-black{
	color: #000 !important;
}

.mini-sidebar .nav-item.active{
	font-weight: bold;
}

.left-sidebar {
	direction: ltr;
}
</style>

<script>
import GlobalVue from '@libs/Global'
import Gen from '@libs/Gen.js'

let $ = global.jQuery
let _ = global._
export default{
  extends:GlobalVue,
	data(){
		return {
			mrKantor: [],
      mrUnitKerja: [],
      mrLevel: []
		}
	},
	computed:{
		picUser(){
			return this.user?.avatar ? this.uploader(this.user.avatar) : this.baseUrl+'/images/user-avatar.svg'
		}
	},
	mounted(){
		this.apiGet()

		$(".sidebartoggler").on('click', function () {
        if ($("body").hasClass("mini-sidebar")) {
            $("body").trigger("resize");
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
						$('.nav-item a').removeClass('text-dark')
        }
        else {
            $("body").trigger("resize");
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
						$('.nav-item a').addClass('text-dark')
        }
    });
    $(".nav-toggler").click(function () {
        $("body").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("ti-menu");
        $(".nav-toggler i").addClass("ti-close");
    });
	},
	methods: {
		doCancelGantiKantor(){
			this.loadingOverlay=true
			Gen.apiRest(
				"/do/GantiKantor",
				{
					data:{
						type: 'cancel-ganti'
					}
				},
				'POST'
			).then(()=>{
				location.reload()
			});
		},
		doClickNotif(val, key){
			if(val.is_read=='N'){
				this.loadingOverlay=true
				Gen.apiRest(
					"/do/read-notif",
					{
						data:{ id:val.id }
					},
					'POST'
				).then(()=>{
					this.loadingOverlay=false
					this.$refs.btnNotif.hide()
					val.is_read="Y"
					this.user.notifications.splice(key,1)
					this.$router.push(val.link)
				});
			}else{
				this.$refs.btnNotif.hide()
				this.$router.push(val.link)
			}
		},
		toReadNotif(){
			// canceled
			/* const ids = _.map(this.user.notifications, 'id').join(',')
			Gen.apiRest(
				"/do/read-notif",
				{
					data:{ type:"all", id: ids }
				},
				'POST'
			).then(()=>{ }); */
		},
		apiGet(params = {}){
      if(this.pageSlug) this.loadingOverlay = true
      else this.loadingOverlayTb = true
      this.data.data = false
      Gen.apiRest(
        "/get/Account/"+this.user.id
      ).then(res=>{
        this.loadingOverlay = false
        this.loadingOverlayTb = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        
        if(!this.isList){
          this.setFocusForm()
        }
        
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
        this.loadingOverlayTb = false
      })
    },
		doSubmitCRUD(IDForm='VForm'){
      return this.doSubmit(
        "/do/Account/"+this.user.id, 
        _.assign({type:'updateOffice'}, {id: this.user.id}, this.row), 
        (type, resp)=>{
          if(type=='success'){
            this.$router.push({name:this.$route.name})
						this.$bvModal.hide('ModalKantor')

						this.row = {
							...this.row,
							bu_unit_kerja_id: "",
							bu_office_id: "",
							bu_level: ""
						}
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                  msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                })
                return global.Swal.fire("Blocked With Validation!", msg)
              }else{
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            }else if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IDForm
      )
    },
	},
	watch: {
		$route(){
			this.apiGet()
		}
	}
}
</script>